<template>
    <div>
      <v-card :loading="loading" :disabled="loading" >
        <v-toolbar
            color="#4ECDC4"
            dark
            flat
        >
          <span class="text-h6">表示設定</span>
        
        </v-toolbar>
      
        <v-card-text class="text-center body-1">
          表示設定を
          <span v-if="item.show" class=" font-weight-bold">非公開</span>
          <span v-else class=" font-weight-bold">公開</span>
          にしますか？
          
        </v-card-text>
         
        <v-card-actions>

          <v-spacer></v-spacer>
          <v-btn 
            class="ma-2"
            outlined
            color="narekomu"   
            @click="save">変更
          </v-btn>
          <v-btn class="ma-2"
            outlined
            color="#78909C"
            @click="close"> 
          キャンセル
           </v-btn>
          <v-spacer></v-spacer>

        </v-card-actions>
      </v-card>


    </div>
      

</template> 

<script>
import axios from "axios"


export default {
  name: 'DialogShow',
  props:["item"],
  data () {
    return {
      navi:this.item,
      loading:false,
    }
  },


  methods: {

    save(){
        this.loading = true;
        let data = {};
          data = {
            "Show":!this.item.show,
          }

          console.log(this.item)
      
          axios({
            method: "PUT",
            url:`${process.env.VUE_APP_URL}${this.$store.state.auth.login.customerId}/navis/${this.item.naviId}`,
            headers: {"X-Api-Key": process.env.VUE_APP_KEY,"SessionId": this.$store.state.auth.login.sessionId, "Content-Type":"application/json" },
            data:data,
          }).then(() => {
              this.loading = false;
              let sendItem = this.item;
              sendItem.show = !this.item.show;
              this.$emit("saveShowEmit",sendItem)
          })
        

    },

    close(){
      this.$emit("closeEmit")
    }
 
  },
}

</script>
